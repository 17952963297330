
import { IonApp, IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonNote, IonRouterOutlet, IonSplitPane } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import { homeOutline, homeSharp, calendarOutline, calendarSharp, briefcase, briefcaseSharp, globeOutline, globeSharp, fingerPrintOutline, fingerPrintSharp, medicalOutline, medicalSharp,
   flagOutline, flagSharp, lockClosedOutline, lockClosedSharp, alertCircle, alertSharp, informationCircleSharp, informationCircleOutline, documentLockSharp, documentLockOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
    IonRouterOutlet,
    IonSplitPane,
  },
  setup() {
    const selectedIndex = ref(0);
    const appPages = [
      {
        title: 'Home',
        url: '/home',
        iosIcon: homeOutline,
        mdIcon: homeSharp
      },
      {
        title: 'Weekly Tasks',
        url: '/weekly',
        iosIcon: calendarOutline,
        mdIcon: calendarSharp
      },
      {
        title: 'Top 12 Tips',
        url: '/tips',
        iosIcon: alertCircle,
        mdIcon: alertSharp
      },
      {
        title: '8 Things To Know',
        url: '/thingstoknow',
        iosIcon: informationCircleOutline,
        mdIcon: informationCircleSharp
      },
      {
        title: 'Passwords',
        url: '/passwords',
        iosIcon: lockClosedOutline,
        mdIcon: lockClosedSharp
      },
      {
        title: 'Websites',
        url: '/websites',
        iosIcon: globeOutline,
        mdIcon: globeSharp
      },
      {
        title: 'Red Flags',
        url: '/redflags',
        iosIcon: flagOutline,
        mdIcon: flagSharp
      },
      {
        title: 'Physical Protection',
        url: '/physical',
        iosIcon: fingerPrintOutline,
        mdIcon: fingerPrintSharp
      },
      {
        title: 'Incident Response',
        url: '/incidentresponse',
        iosIcon: medicalOutline,
        mdIcon: medicalSharp
      },
      {
        title: 'Secure Setup',
        url: '/securesetup',
        iosIcon: documentLockOutline,
        mdIcon: documentLockSharp
      },
      {
        title: 'Legal',
        url: '/legal',
        iosIcon: briefcase,
        mdIcon: briefcaseSharp
      },
    ];
    const path = window.location.pathname.split('/')[1];
    if (path !== undefined) {
      selectedIndex.value = appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }

    const route = useRoute();

    return {
      selectedIndex,
      appPages,
      isSelected: (url: string) => url === route.path ? 'selected' : ''
    }
  }
});
